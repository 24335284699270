import { useRef } from "react";
import { Link } from "react-router-dom";

interface IMatchItemComponent {
  id: string;
  tournamentName: string;
  firstCompetitorName: string;
  secondCompetitorName: string;
}

export const MatchItemComponent = ({
  id,
  tournamentName,
  firstCompetitorName,
  secondCompetitorName,
}: IMatchItemComponent) => {
  const firstImageEl = useRef<HTMLImageElement>(null);
  const secondImageEl = useRef<HTMLImageElement>(null);

  return (
    <Link className="match-item-wrapper" key={id} to={`/match/${id}`}>
      <img
        className="match-item-img"
        alt="First Competitor Logo"
        src={`https://raw.githubusercontent.com/lootmarket/esport-team-logos/master/csgo/${secondCompetitorName.toLowerCase()}/${secondCompetitorName.toLowerCase()}-logo.png`}
        onError={() => {
          if (firstImageEl.current) {
            firstImageEl.current.src = `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd2NAjCcjjk7ac57mKCQvgWVTmP0ysxnzQnQ&s`;
          }
        }}
        ref={firstImageEl}
      />
      <h1>VS</h1>
      <img
        className="match-item-img second-image"
        alt="Second Competitor Logo"
        src={`https://raw.githubusercontent.com/lootmarket/esport-team-logos/master/csgo/${firstCompetitorName.toLowerCase()}/${firstCompetitorName.toLowerCase()}-logo.png`}
        onError={() => {
          if (secondImageEl.current) {
            secondImageEl.current.src = `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd2NAjCcjjk7ac57mKCQvgWVTmP0ysxnzQnQ&s`;
          }
        }}
        ref={secondImageEl}
      />
      <h2>
        {tournamentName} - {firstCompetitorName} x {secondCompetitorName}
      </h2>
    </Link>
  );
};
