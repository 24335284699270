


interface IPaginatorComponent {
    page: number;
    perPage: number;
    setPage: (newPage: number) => void;
    matchCount: number;
}

export const PaginatorComponent = ({
    page,
    perPage,
    setPage,
    matchCount
}: IPaginatorComponent) => {
    const onPreviousPage = () => setPage(page - 1);
    const onNextPage = () => setPage(page + 1);
    const totalPages = Math.ceil(matchCount / perPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
return <div>
        {page > 1 && (
            <button onClick={onPreviousPage}>Previous page</button>
        )}
           {pageNumbers.map((pageNumber) => (
                <button
                    key={pageNumber}
                    onClick={() => setPage(pageNumber)}
                    disabled={pageNumber === page}
                >
                    {pageNumber}
                </button>
            ))}
        {page * perPage < matchCount && (
            <button onClick={onNextPage}>Next page</button>
        )}
    </div>
}