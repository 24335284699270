import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Chart } from "react-google-charts";
import "./Match.css";
const API_URL = process.env.REACT_APP_API_URL ?? "http://127.0.0.1:5000";

function Match() {
  const { id } = useParams();
  const [match, setMatch] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredMarkets, setFilteredMarkets] = useState<Array<any>>([]);
  const [chartData, setChartData] = useState<any>(null);
  const chartDataRef = useRef({});

  const matchDate = new Date(match?.scheduledAt);

  useEffect(() => {
    axios.get(`${API_URL}/matches/${id}`).then((response) => {
      setMatch(response.data);
      setFilteredMarkets(response.data.markets);
      let marketsMap: Record<any, any> = {};
      for (const market of response.data.markets) {
        if (marketsMap.hasOwnProperty(market.name)) {
          marketsMap[market.name].outcomes = [
            ...marketsMap[market.name].outcomes,
            ...market.outcomes,
          ];
        } else {
          marketsMap[market.name] = {
            ...market,
            competitorNames: [
              market.outcomes[0].competitor.name,
              market.outcomes[1].competitor.name,
            ],
          };
        }
      }
      setFilteredMarkets(Object.values(marketsMap));
    });
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (filteredMarkets.length === 0 || !match) return;
      let newChartDataMap: Record<any, any> = {};
      for (const market of filteredMarkets) {
        const dataToPush: any = [
          [
            "Time",
            { role: "annotation", type: "string" },
            market.competitorNames[0],
            market.competitorNames[1],
          ],
        ];
        for (const outcome of market.outcomes) {
          const outcomeResponse = await axios.get(
            `${API_URL}/outcomes/${outcome.id}`
          );
          const outcomeData = outcomeResponse.data;
          if (outcome.competitor.name === market.competitorNames[0]) {
            outcomeData.outcome_odds.forEach((odd: any) => {
              dataToPush.push([new Date(odd.createdAt), null, odd.odd, null]);
            });
          } else if (outcome.competitor.name === market.competitorNames[1]) {
            outcomeData.outcome_odds.forEach((odd: any) => {
              dataToPush.push([new Date(odd.createdAt), null, null, odd.odd]);
            });
          }
        }
        dataToPush.push([
          new Date(match.scheduledAt),
          "Match Start",
          null,
          null,
        ]);
        newChartDataMap[market.name] = dataToPush;
      }
      chartDataRef.current = newChartDataMap;
      setChartData(newChartDataMap);
      setLoading(false);
    };

    fetchData();
  }, [filteredMarkets, match]);

  return (
    <div>
      {match && (
        <>
          <a href="/" className="back-button">
            Back to menu
          </a>

          <div className="match-wrapper-header">
            <div className="match-date-wrapper">
              <h1>
                {matchDate.getDate()}/{matchDate.getMonth() + 1}{" "}
                {matchDate.getHours()}:
                {matchDate.getMinutes() < 10
                  ? "0" + matchDate.getMinutes()
                  : matchDate.getMinutes()}
              </h1>
            </div>
            <h1>
              {match.tournament.name} - {match.competitors[0].name} x{" "}
              {match.competitors[1].name}
            </h1>
          </div>

          <p>{match.date}</p>
          <p>{match.location}</p>
          <p>{match.result}</p>
          {loading ? (
            <h3>Loading...</h3>
          ) : (
            filteredMarkets.map((market: any) => {
              return (
                <div className="match-stat-container" key={market.id}>
                  <h2>{market.name}</h2>
                  <Chart
                    width={"100%"}
                    height={"300px"}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={chartData[market.name]}
                    options={{
                      hAxis: {
                        title: "Time",
                      },
                      vAxis: {
                        title: "Odds",
                      },
                      annotations: {
                        stem: {
                          color: "#097138",
                        },
                        style: "line",
                      },
                      legend: { position: "top" },
                    }}
                  />
                </div>
              );
            })
          )}
        </>
      )}
    </div>
  );
}

export default Match;
