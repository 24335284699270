import axios from "axios";
import { useEffect, useState } from "react";
import "./Home.css";
import { MatchItemComponent } from "../components/MatchItemComponent";
import { PaginatorComponent } from "../components/PaginatorComponent";

const API_URL = process.env.REACT_APP_API_URL ?? "http://127.0.0.1:5000";

const fetchMatches = async (page: number) => {
  const matches = axios.get(`${API_URL}/matches`, {
    params: {
      page: page,
    },
  });
  return matches;
};

const perPage = 10;
function Home() {
  const [matches, setMatches] = useState([]);
  const [matchCount, setMatchCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  console.log(matches);
  useEffect(() => {
    fetchMatches(page).then((matches) => {
      setMatches(matches.data.data);
      setMatchCount(matches.data.count);
    });
  }, [page]);

  return (
    <div className="Home">
      <div className="header">
        <h1>Rivalry Tracker</h1>
        <p>
          Matches: <b>{matchCount}</b>
        </p>
      </div>
      <div className="matches-container">
        {matches.map((match: any) => {
          return (
            <MatchItemComponent
              id={match.id}
              key={match.id}
              tournamentName={match.tournament.name}
              firstCompetitorName={match.competitors[0].name}
              secondCompetitorName={match.competitors[1].name}
            />
          );
        })}
      </div>

      <PaginatorComponent
        page={page}
        perPage={perPage}
        setPage={setPage}
        matchCount={matchCount}
      />
    </div>
  );
}

export default Home;
